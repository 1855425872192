import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Flex,
  Heading,
  Image,
} from "@chakra-ui/react";
import { ProductState } from "./ProductState";
import { DefinitionItem } from "../elements";
import { ProductPrice } from "../products/ProductPrice";
import { AddToCartButton } from "./AddToCartButton"
import { ProductActionMenu } from "./ProductActionMenu"

export const DisplayListItem = ({ product }) => {
  return (
    <Flex gap={4} direction="row" alignItems="center">
      <Box
        flex={`128px 0 0`}
        height={`128px`}
        position="relative"
      >
        <Link to={product.href}>
          <Image src={product.profile_picture.thumb.url} />
        </Link>
        <ProductState position="absolute" bottom={0} left={0} roundedLeft={0} product={product} />
      </Box>
      <Flex
        flexGrow={1}
        justifyContent="stretch"
        alignItems="stretch"
        direction="column"
      >
        <Flex
          alignItems="center"
          gap={2}
        >
          <Heading noOfLines={1} size="sm" as="div">
            {product.title}
          </Heading>
          <Box minWidth="10px" flexGrow="1" borderBottomWidth="2px" />
          <ProductPrice taxHint={false} product={product} />
          <Box minWidth="10px" borderBottomWidth="2px" />
          <ProductActionMenu product={product} />
        </Flex>
        <Flex alignItems="center" direction="row">
          <Flex mr="auto" alignItems="flex-start" direction="column">
            <DefinitionItem label={product.release_date.label}>
              {product.release_date.value}
            </DefinitionItem>
            <AddToCartButton product={product} />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
