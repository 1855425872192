import React from "react";
import { useLocation } from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import {
  useColorModeValue,
  List,
  SimpleGrid,
  Image,
  Heading,
  Box,
  Flex,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { useFetchProducts } from "../api";
import countdown0 from "../images/countdown-0.png"
import countdown1 from "../images/countdown-1.png"
import countdown2 from "../images/countdown-2.png"
import countdown3 from "../images/countdown-3.png"
import countdown4 from "../images/countdown-4.png"
import countdown5 from "../images/countdown-5.png"
import countdown6 from "../images/countdown-6.png"
import countdown7 from "../images/countdown-7.png"
import countdown8 from "../images/countdown-8.png"
import { levels, Level } from "../home/CountdownDealBanner";

export const CountdownDeals = () => {
  const bg = useColorModeValue("whiteAlpha.900", "blackAlpha.900")
  const { locale } = useParams();
  const productsQuery = useFetchProducts({ term: "*", stock_state: ["sold_out"], discount_stage: ["10", "15", "20", "25", "30", "35"], per: 192 })
  return (
    <Flex direction="column" gap="4">
      <Flex
        direction="column"
        justifyContent="center"
        bg="orange.600"
        color="orange.50"
        fontSize="lg"
        p={[2, 4]}>
        <Breadcrumb separator={<FontAwesomeIcon icon={faChevronRight} />}>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to={`/${locale}`}>
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to={`/${locale}/countdown-deals`}>
              Rabatt Staffel
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Heading as="h1">Rabatt Staffel Deals</Heading>
        <Box flex="1" size="sm" color="orange.200" fontWeight="semibold">
          🌟 Level Up! Neue Rabatte bei jeder Stufe! 🌟
        </Box>
      </Flex>
      <Box position="relative">
        <Flex position="absolute" top="4" left="4" right="4" p="4" maxW="800px" mx="auto" bg={bg} rounded="md" fontSize="xl" direction="column" gap="2">
          <Heading as="h2">
            🔥 Der größte Sale aller Zeiten bei Figuya! 🔥
          </Heading>
          <Box>
            Über 2000 Produkte im Angebot. Jede Stufe bringt neue Rabatte – und neue Entscheidungen!
          </Box>
          <Box>
            ⚠️ Aber aufgepasst: Was weg ist, ist weg! ⚠️
          </Box>
          <Box>
            Hol dir jetzt deinen Schatz oder riskiere, dass jemand anderes schneller zuschlägt. Bist du bereit, aufs nächste Level zu warten – oder machst du Nägel mit Köpfen?
          </Box>
          <Box>
            👉 Deine Wahl, dein Risiko, dein Anime-Abenteuer!
          </Box>
          <Flex direction={["column", "column", "row"]} mx="-4" my="4">
            <Box as={Link} to={`/de/products?discount_stage=10&discount_stage=15&discount_stage=20&discount_stage=25&discount_stage=30&order=popularity`} _hover={{ bg: "orange.600" }} flex="1" p="4" bg="orange.500" color="orange.50" fontWeight="semibold" textAlign="center">
              Zu den rabattierten Produkte
            </Box>
            <Box _hover={{ bg: "red.600" }} as={Link} to={`/de/products?&discount_stage=10&discount_stage=15&discount_stage=20&discount_stage=25&discount_stage=30&order=popularity&availability=last`} flex="1" p="4" bg="red.500" color="red.50" fontWeight="semibold" textAlign="center">
              Nur letzte Exemplare anzeigen
            </Box>
          </Flex>
          <List p="4" justifyContent="flex-start" display="flex" flexDirection="column" gap="4">
            {levels.map(level => <Level {...level} key={level.level}>
              <Flex position="relative" direction="row" wrap="wrap" gap="1" py="2">
                {productsQuery.data.products.filter(product => product.discount_stage === level.stage).map(item => <Box width="64px" height="64px" key={item.id}>
                  {item.profile_picture.small_profile_webp && <Image filter="grayscale(90%)" src={item.profile_picture.small_profile_webp.url} />}
                </Box>)}
                {productsQuery.data.products.filter(product => product.discount_stage === level.stage).length > 0 &&
                  <Flex position="absolute" top="0" right="0" left="0" bottom="0" alignItems="center" justifyContent="center">
                    <Box bg={bg} px="4" py="2" rounded="sm" color="red.700" fontWeight="bold" fontSize="xl" transform="rotate(-10deg)">Leider verpasst</Box>
                  </Flex>}
              </Flex>
            </Level>)}
          </List>
        </Flex>
        <SimpleGrid columns={[1, 1, 2, 2]} gap="4px">
          <Box>
            <Image src={countdown0} />
          </Box>
          <Box>
            <Image src={countdown1} />
          </Box>
          <Box>
            <Image src={countdown2} />
          </Box>
          <Box>
            <Image src={countdown3} />
          </Box>
          <Box>
            <Image src={countdown4} />
          </Box>
          <Box>
            <Image src={countdown5} />
          </Box>
          <Box>
            <Image src={countdown6} />
          </Box>
          <Box>
            <Image src={countdown7} />
          </Box>
        </SimpleGrid>
      </Box>
    </Flex>
  );
};
