import React from "react";
import {
  Routes,
  Outlet,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { Box, useColorModeValue } from "@chakra-ui/react";
import { MainLayout } from "./layout";
import { Products, Product } from "./products";
import { SubscribeToTaxon, Taxon } from "./taxons";
import { Landingpage } from "./Landingpage";
import { ProductsLayout } from "./products/ProductsLayout";
import { HelpDeskWelcomeDe } from "./help-desk";
import { Question } from "./help-desk/Question";
import { Taxonomy } from "./taxonomies/Taxonomy";
import { NotFound } from "./static/NotFound";
import { ServerError } from "./static/ServerError";
import { TaxonSuggestions } from "./taxons/TaxonSuggestions";
import { CountdownDeals } from "./products/CountdownDeals.js";

const SocialLinks = React.lazy(() => import("./SocialLinks"))
const Sale = React.lazy(() => import("./sales/Sale"));
const Homepage = React.lazy(() => import("./home/Homepage"));
const TopList = React.lazy(() => import("./home/TopList"));
const NewPaypalExpressOrder = React.lazy(() =>
  import("./checkout/NewPaypalExpressOrder")
);
const UserRoutes = React.lazy(() => import("./users/UserRoutes"));
const AccountRoutes = React.lazy(() => import("./accounts/AccountRoutes"));
const CheckoutRoutes = React.lazy(() => import("./checkout/CheckoutRoutes"));
const Reservierungsinformationen = React.lazy(() =>
  import("./static/Reservierungsinformationen")
);
const Widerrufsrecht = React.lazy(() => import("./static/Widerrufsrecht"));
const AGB = React.lazy(() => import("./static/AGB"));
const DSE = React.lazy(() => import("./static/DSE"));
const Bestellablauf = React.lazy(() => import("./static/Bestellablauf"));
const Legal = React.lazy(() => import("./static/Legal"));
const Impressum = React.lazy(() => import("./static/Impressum"));
const Calendar = React.lazy(() => import('./advent23/Calendar'))
const Versandinformationen = React.lazy(() =>
  import("./static/Versandinformationen")
);
const UeberUns = React.lazy(() => import("./static/UeberUns"));
const AboutUs = React.lazy(() => import("./static/AboutUs"));
const ShoppingCart = React.lazy(() => import("./cart/ShoppingCart"));
const GuestOrder = React.lazy(() => import("./orders/GuestOrder"));
const Jobs = React.lazy(() => import("./static/Jobs"));
const StoreManager = React.lazy(() => import("./static/jobs/StoreManager.js"));
const Augsburg = React.lazy(() => import("./static/jobs/Augsburg"));
// const Accountant = React.lazy(() => import("./static/jobs/Accountant"));
// const Dresden = React.lazy(() => import("./static/jobs/Dresden"));
// const LeipzigOktober = React.lazy(() => import("./static/LeipzigOktober"));
const TaxonomyByTaxon = React.lazy(() =>
  import("./taxonomies/TaxonomyByTaxon")
);

const Readable = () => {
  const bg = useColorModeValue("white", "gray.800");
  return (
    <Box bg={bg}>
      <Box pb="100px" px={[0, 2, 4]} maxWidth="600px" m="auto">
        <Outlet />
      </Box>
    </Box>
  );
};

const NoMatch = () => <Box>Nicht gefunden</Box>;
export const FiguyaRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  React.useEffect(() => {
    if (location.pathname.match(new RegExp("warenkorb"))) {
      const newPathname = location.pathname.replace("warenkorb", "cart");
      return navigate({
        pathname: newPathname,
        search: location.search,
        hash: location.hash,
      });
    }
    if (location.pathname.match(new RegExp("produkte"))) {
      const newPathname = location.pathname.replace("produkte", "products");
      return navigate({
        pathname: newPathname,
        search: location.search,
        hash: location.hash,
      });
    }
  }, [location.pathname]);
  return (
    <React.Suspense
      fallback={<Box bg="orange.600" width="100vw" height="100vh" />}
    >
      <Routes>
        <Route index element={<Landingpage />} />
        <Route path=":locale/checkout/*" element={<CheckoutRoutes />} />
        <Route
          path=":locale/new-paypal-express-order/:id"
          element={<NewPaypalExpressOrder />}
        />
        <Route path=":locale/*" element={<MainLayout />}>
          <Route index element={<Homepage />} />
          <Route path="social-links" element={<SocialLinks />} />
          <Route path="advent" element={<Calendar />} />
          <Route path="help-desk">
            <Route index element={<HelpDeskWelcomeDe />} />
            <Route path=":id" element={<Question />} />
            <Route path="*" element={<Box height="100%" bg="aqua" />} />
          </Route>
          <Route path="taxonomies/:taxonomyId">
            <Route index element={<Taxonomy />} />
            <Route path=":taxonId" element={<TaxonomyByTaxon />} />
          </Route>
          <Route path="cart" element={<ShoppingCart />} />
          <Route path="countdown-deals" element={<CountdownDeals />} />
          <Route element={<ProductsLayout />} path="products">
            <Route path="wide" element={<Products />} />
            <Route path="description" element={<Products />} />
            <Route index path="*" element={<Products />} />
          </Route>
          <Route path="products/*">
            <Route path="toplist" element={<TopList />} />
            <Route path=":slug/*" element={<Product />}>
              <Route path="subscribe/:taxonId" element={<SubscribeToTaxon />} />
            </Route>
          </Route>
          <Route path="sales/:saleId">
            <Route index element={<Sale />} />
            <Route path=":slug/*" element={<Product />} />
          </Route>
          <Route path="taxons/*">
            <Route index element={<Box h="400px" bg="tomato" />} />
            <Route path=":taxonId/*" element={<TaxonSuggestions />}>
              <Route path="*" index element={<Taxon />} />
              <Route path="subscribe" element={<SubscribeToTaxon />} />
            </Route>
            <Route path=":taxonId/products/*" element={<TaxonSuggestions />}>
              <Route path=":slug/*" element={<Product />}>
                <Route path="subscribe/:subId" element={<SubscribeToTaxon />} />
              </Route>
            </Route>
          </Route>
          <Route path="accounts/*" element={<AccountRoutes />} />
          <Route path="user/*" element={<UserRoutes />} />
          <Route element={<Readable />}>
            <Route path="jobs">
              <Route index element={<Jobs />} />
              <Route path="augsburg" element={<Augsburg />} />
              <Route path="manager" element={<StoreManager />} />
              {/* <Route path="leipzig" element={<LeipzigOktober />} /> */}
              {/* <Route path="accountant" element={<Accountant />} /> */}
              {/* <Route path="dresden" element={<Dresden />} /> */}
            </Route>
            <Route path="orders/:id" element={<GuestOrder />} />
            <Route path="not-found" element={<NotFound />} />
            <Route path="error" element={<ServerError />} />
            <Route path="about" element={<AboutUs />} />
            <Route path="ueber-uns" element={<UeberUns />} />
            <Route path="impressum" element={<Impressum />} />
            <Route path="legal" element={<Legal />} />
            <Route path="agb" element={<AGB />} />
            <Route path="terms" element={<AGB />} />
            <Route path="dse" element={<DSE />} />
            <Route path="privacy" element={<DSE />} />
            <Route path="bestellablauf" element={<Bestellablauf />} />
            <Route
              path="versandinformationen"
              element={<Versandinformationen />}
            />
            <Route path="widerruf" element={<Widerrufsrecht />} />
            <Route
              path="reservierungsinformationen"
              element={<Reservierungsinformationen />}
            />
          </Route>
        </Route>
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </React.Suspense>
  );
};
export default FiguyaRoutes;
