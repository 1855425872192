import {
  useQueryParams,
  NumberParam,
  StringParam,
  ArrayParam,
  withDefault,
} from "use-query-params";
import debounce from "lodash-es/debounce";
import merge from "lodash-es/merge";

export const useProductSearchParams = (initialQuery = {}) => {
  const defaultQuery = {
    page: 1,
    term: "",
    set_operation: "intersection",
    view: "grid",
    order: "touched_at",
    direction: "desc",
    stock_state: ["stocked", "backorder", "preorder"],
    discount_stage: [],
    availability: [],
    taxon_ids: [],
    ...initialQuery,
  };
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, defaultQuery.page),
    gross_selling_price_max: NumberParam,
    set_operation: StringParam,
    term: withDefault(StringParam, defaultQuery.term),
    view: withDefault(StringParam, defaultQuery.view),
    order: withDefault(StringParam, defaultQuery.order),
    direction: withDefault(StringParam, defaultQuery.direction),
    stock_state: withDefault(ArrayParam, defaultQuery.stock_state),
    availability: withDefault(ArrayParam, defaultQuery.availability),
    taxon_ids: withDefault(ArrayParam, defaultQuery.taxon_ids),
    sale_id: withDefault(StringParam, defaultQuery.sale_id),
    discount_stage: withDefault(ArrayParam, defaultQuery.discount_stage)
  });

  const debouncedSetQuery = debounce((q) => {
    const newQuery = merge(initialQuery, q);
    return setQuery(newQuery);
  }, 300);
  return [query, setQuery];
};
