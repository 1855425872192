import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  Circle,
  Flex,
  Box,
  useDisclosure,
  useBreakpointValue,
  useColorModeValue,
  Collapse,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "../elements";
import { useUiStore, useSearchUi } from "../hooks";
import { faCartShoppingFast } from "@fortawesome/pro-solid-svg-icons/faCartShoppingFast";
import { faBars } from "@fortawesome/pro-solid-svg-icons/faBars";
import { faHeart } from "@fortawesome/pro-solid-svg-icons/faHeart";
import { faClose } from "@fortawesome/pro-solid-svg-icons/faClose";
import { faSearch } from "@fortawesome/pro-solid-svg-icons/faSearch";
import { faArrowUp } from "@fortawesome/pro-solid-svg-icons/faArrowUp";
import { useTranslation } from "react-i18next";
import { useFetchCart, useFetchMenu } from "../api/queries";
import { CartPopover } from "../cart/CartPopover";
import { UserLink } from "./UserLink";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { TaxonomyMenu } from "./TaxonomyMenu";

const IconNavigation = ({ isOpen, onToggle }) => {
  const ui = useSearchUi();
  const userUi = useUiStore();
  const { t } = useTranslation();
  const isCollapsing = useBreakpointValue(
    { base: true, lg: false },
    { ssr: false }
  );
  const size = "md";

  return (
    <Flex mr={[2, 4]} order="1" alignItems="center" gap={2}>
      {isCollapsing && (
        <NavLink aria-label="toggle menu" size={size} onClick={onToggle}>
          <FontAwesomeIcon icon={isOpen ? faClose : faBars} />
        </NavLink>
      )}
      <NavLink aria-label="open search" onClick={ui.openSearch} size={size}>
        <FontAwesomeIcon icon={faSearch} />
      </NavLink>
      <NavLink
        aria-label="scroll to top"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        size={size}
      >
        <FontAwesomeIcon icon={faArrowUp} />
      </NavLink>
      {userUi.signedIn &&
        (isCollapsing ? (
          <NavLink
            aria-label="search taxon subscriptions"
            size={size}
            onClick={ui.openSubscriptions}
          >
            <FontAwesomeIcon icon={faHeart} />
          </NavLink>
        ) : (
          <NavLink
            aria-label="search taxon subscriptions"
            size={size}
            leftIcon={<FontAwesomeIcon icon={faHeart} />}
            onClick={ui.openSubscriptions}
          >
            {t("taxon-subscriptions")}
          </NavLink>
        ))}
    </Flex>
  );
};

const CatalogueLinks = ({ isOpen, containerRef }) => {
  const homeQuery = useFetchMenu();
  const contentBg = useColorModeValue("white", "gray.800");
  const size = "md";
  const position = useBreakpointValue({ base: 3, lg: 2 }, { ssr: false });
  const isCollapsing = useBreakpointValue(
    { base: true, lg: false },
    { ssr: false }
  );

  return (
    <Box width={isCollapsing ? "100%" : "auto"} order={position}>
      {isCollapsing ? (
        <Box p={[2, 4]} borderBottomWidth="2px" bg={contentBg} position="absolute" top="48px" left="0" right="0" display={isOpen ? 'block' : 'none'}>
          <Items
            containerRef={containerRef}
            size={size}
            isCollapsing={isCollapsing}
            main_items={homeQuery.data.main_items}
          />
        </Box>
      ) : (
        <Items
          containerRef={containerRef}
          size={size}
          isCollapsing={isCollapsing}
          main_items={homeQuery.data.main_items}
        />
      )}
    </Box>
  );
};
const Items = ({ isCollapsing, main_items, size, containerRef }) => {
  const { locale } = useParams();
  const [taxonomyId, setTaxonomyId] = React.useState(null)
  const { t } = useTranslation();
  return (
    <Flex wrap="wrap" my={isCollapsing ? 4 : 0} direction={isCollapsing ? "column" : "row"} gap={[2, 4]}>
      <NavLink size={size} to={`/${locale}/products`}>
        {t("all-products")}
      </NavLink>
      {main_items.map((taxonomy) => (
        <TaxonomyMenu
          taxonomyId={taxonomyId}
          setTaxonomyId={setTaxonomyId}
          containerRef={containerRef}
          size={size}
          taxonomy={taxonomy}
          key={taxonomy.id}
        ></TaxonomyMenu>
      ))}
    </Flex>
  );
};

const CartAccountLinks = () => {
  const position = useBreakpointValue({ base: 2, lg: 3 }, { ssr: false });
  const cartQuery = useFetchCart();
  const ui = useUiStore();
  const { locale } = useParams();
  const { t } = useTranslation();
  const { cart_items } = cartQuery.data;
  const cartLength = cart_items.reduce((res, elem) => res + elem.quantity, 0);
  return (
    <Flex order={position} gap="4" ml="auto">
      <UserLink />
      <CartPopover cart={cartQuery.data}>
        <NavLink
          size="xl"
          position="relative"
          color="orange.50"
          onMouseEnter={ui.enableCartPopover}
          as={Link}
          to={`/${locale}/cart`}
          leftIcon={<FontAwesomeIcon icon={faCartShoppingFast} />}
        >
          {t("shopping-cart")}
          <AnimatePresence>
            {cartLength > 0 && (
              <Circle
                key={cartLength}
                as={motion.div}
                initial={{ scale: 0.1 }}
                animate={{ scale: [2, 0.5, 1] }}
                exit={{ scale: 0.1 }}
                fontSize="sm"
                fontWeight="300"
                position="absolute"
                bottom={cartLength > 9 ? -6 : -5}
                right={-1}
                bg="green.500"
                d="flex"
                borderColor="green.50"
                color="green.50"
                alignItems="center"
                justifyContent="center"
                size={cartLength > 9 ? "28px" : "24px"}
                borderWidth="2px"
              >
                {cartLength}
              </Circle>
            )}
          </AnimatePresence>
        </NavLink>
      </CartPopover>
    </Flex>
  );
};

export const BottomPart = () => {
  const location = useLocation();
  const contentBg = useColorModeValue("white", "gray.800");
  const containerRef = React.useRef()

  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  React.useEffect(() => onClose, [location.pathname, location.search]);
  return (
    <>
      <Box
        borderBottomWidth="2px"
        height="48px"
        zIndex="15"
        top={0}
        position="sticky"
        direction="column"
        alignItems="center"
        bg={contentBg}
      >
        <Flex
          position="relative"
          direction="row"
          px={[2, 4]}
          py="2"
          maxWidth="1400px"
          m="auto"
          alignItems="center"
          wrap="wrap"
          zIndex="100"
        >
          <IconNavigation isOpen={isOpen} onToggle={onToggle} />
          <CatalogueLinks containerRef={containerRef} isOpen={isOpen} />
          <CartAccountLinks />
        </Flex>
      </Box>
      <Box
        position="relative"
        maxWidth="1400px"
        m="auto"
        ref={containerRef} />
    </>
  );
};
